import {Canvas, useFrame} from '@react-three/fiber'
import {useGLTF} from '@react-three/drei'
import React, { useRef } from "react";
import './CV.css'


export const ManyAsteoroides=()=>{
  
    return(
        <Canvas
        camera={{position:[-500,-150,-150], fov:90}}
        eventSource={document.getElementById('root')}
        eventPrefix='client'
        style={{
            width:'100vw',
            height:'330vh'
        }}
        >
           <Meteorites/>
           <pointLight position={[500, 500, 500]} />
           <pointLight position={[-500, -500, -500]} />
        </Canvas>
    )
}


export function Meteorites(props){
    const group=useRef()
    useFrame((state, delta) => (
        group.current.rotation.y -= 0.000238
        ))
    useFrame((state, delta) => (
        group.current.rotation.x -= 0.000238
        ))
    const { nodes, materials } = useGLTF("/manyasteroids.glb");
    return (
      <group ref={group} {...props} dispose={null}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube.geometry}
          material={materials.Rock}
          position={[-260.522, -55.152, -271.65]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube001.geometry}
          material={materials["Rock.001"]}
          position={[-189.786, -5.534, -203.726]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube002.geometry}
          material={materials["Rock.002"]}
          position={[-307.409, 8.716, 68.921]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube003.geometry}
          material={materials["Rock.003"]}
          position={[-406.612, 27.297, 233.022]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube004.geometry}
          material={materials["Rock.004"]}
          position={[-517.536, 54.296, -106.374]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube005.geometry}
          material={materials["Rock.005"]}
          position={[-318.054, -83.458, 0.05]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube006.geometry}
          material={materials["Rock.006"]}
          position={[-303.387, 8.716, 353.348]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube007.geometry}
          material={materials["Rock.007"]}
          position={[-735.786, 8.716, 102.991]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube008.geometry}
          material={materials["Rock.008"]}
          position={[-385.272, -23.692, 0.05]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube009.geometry}
          material={materials["Rock.009"]}
          position={[-251.645, -59.63, 0.05]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube010.geometry}
          material={materials["Rock.010"]}
          position={[-271.691, -0.51, -219.507]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube011.geometry}
          material={materials["Rock.011"]}
          position={[341.566, -59.63, 0.05]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube012.geometry}
          material={materials["Rock.012"]}
          position={[226.41, 190.156, -219.507]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube013.geometry}
          material={materials["Rock.013"]}
          position={[270.426, -23.692, 366.106]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube014.geometry}
          material={materials["Rock.014"]}
          position={[481.459, 27.297, 233.022]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube015.geometry}
          material={materials["Rock.015"]}
          position={[256.902, -288.942, 0.05]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube016.geometry}
          material={materials["Rock.016"]}
          position={[495.742, -59.63, 0.05]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube017.geometry}
          material={materials["Rock.017"]}
          position={[43.673, -23.692, 349.441]}
        />
      </group>
    );
  
  }