import React from 'react'
import Drawing from "./Drawing";
import './AppDrawingOpinion.css'

function AppDrawingOpinion() {
  return (
    <div className='AppDO'>
      <Drawing/>
    </div>
  )
}

export default AppDrawingOpinion