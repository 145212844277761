import React from 'react'
import AboutUs from '../palm/AboutUs/AboutUs.js'
import './PalmApp.css'

function PalmApp() {
  return (
    <div className='PalmAppPalm'>
      <AboutUs/>
    </div>
  )
}

export default PalmApp