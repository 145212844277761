import {Canvas, useFrame} from '@react-three/fiber'
import { Center, useGLTF} from '@react-three/drei'
import React, { useRef } from "react";
import './CV.css'

export const Planet=({position=[1,.3,3], fov=50})=>(
    <Canvas
    camera={{position, fov}}
    eventSource={document.getElementById('root')}
    eventPrefix='client'
    style={{
        width:'80vw',
        height:'50vh'
    }}
    >
    {/* <RandomizedLight
       amount={10}
       intensity={3.2}
       ambient={1.55}
       position={[1,-1,-1]}
      /> */}
       <pointLight position={[9, 15, 7]} />
      <Center>
        <FirstPlanet/>
      </Center>
    </Canvas>
)

function FirstPlanet(props){
    const group=useRef()
    const { nodes, materials } = useGLTF("/Planet.glb");
    useFrame((state, delta) => (
        group.current.rotation.y += 0.000238
        ))
    return (
      <group ref={group} {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere.geometry}
        material={materials.Material}
      />
      </group>
    );
}


