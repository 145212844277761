import React, {  useState } from 'react'
import axios from 'axios'
import FileDownload from 'js-file-download'
import Navigation from '../../navigation/Navigation'
import {  Planet } from './Planet'
import { Meteorite} from './Meteorite'
import { ManyAsteoroides} from './ManyAsteroides'
import './CV.css'



function CV() {
  
  const download=(e)=>{
    e.preventDefault()
    axios({
      url:"/api/dowlandcv",
      method:"GET",
      responseType:"blob"
    }).then((res)=>{
      FileDownload(res.data,"CV Charkiewicz Paweł.pdf")
    })
  }
///////////////////////Move Element Asteriode//////////////////////////////////
const [displayMeteorite,setDisplayMeteorite]=useState(true)


const MeteoritRandomFov=[
   Math.floor(Math.random()*50+10),
   Math.floor(Math.random()*50+10),
   Math.floor(Math.random()*50+10),
   Math.floor(Math.random()*50+10),
   Math.floor(Math.random()*50+10),
   Math.floor(Math.random()*50+10),
]

const MeteoritTop=[
   Math.floor(Math.random()*250+40),
   Math.floor(Math.random()*250+40),
   Math.floor(Math.random()*250+40),
   Math.floor(Math.random()*250+40),
   Math.floor(Math.random()*250+40),
   Math.floor(Math.random()*250+40),
]

const MetheorRandomSpeed=[
   (Math.random()*10)/100,
   (Math.random()*10)/100,
   (Math.random()*10)/100,
   (Math.random()*10)/100,
   (Math.random()*10)/100,
   (Math.random()*10)/100,

]

const Metheoridrotation=[
   (Math.random()*9)/1000,
   (Math.random()*9)/1000,
   (Math.random()*9)/1000,
   (Math.random()*9)/1000,
   (Math.random()*9)/1000,
   (Math.random()*9)/1000,
]

  return (
      <div className='cvMain_container'>
         <div className='cvnav_container'>
           <Navigation
              displayAll={true}
              sideList={true}
              links={false}
           />
         </div>
         <button
           onClick={()=>{setDisplayMeteorite(!displayMeteorite)}} 
           className={displayMeteorite?"redBtn switchMeteorite":"greenBtn switchMeteorite"}
         >
            {displayMeteorite?"Wyłącz meteoryty":"Włącz metoryty"}
         </button>
         {
            displayMeteorite&&         
         <div className='manyAsteroides'>
             <ManyAsteoroides/>
         </div>
         }
         {
            displayMeteorite&&
         
         <div className='manyMeteoride'>
         {
            MeteoritRandomFov.map((item,index)=>{
               return(
                  <div key={index}>
                     <Meteorite 
                        Numberfov={item}
                        NumberTop={MeteoritTop[index]} 
                        Speed={MetheorRandomSpeed[index]} 
                        Metheoridrotation={Metheoridrotation[index]} 
                  
                     />
                  </div>
               )
            })
         }
          
         </div>
         }  
         <div className='planetCv'>
           <Planet/> 
           
         </div> 
        
           <div className='cvBoard_container'>
            <div className='cv_container'>
                <div className='cvImg_container'>
                 
                  <p>Curriculum vitae</p>
                </div>

                <div className='boardtoOtherCv'>
                    <div className='cvLeftSide'>
                       <div className='personalData_container'>
                           <p className='datacv'>Dane</p>
                           <p className='dataOtherParagtapf'>Data urodzenia: 06.07.1990</p>
                           <p className='dataOtherParagtapf'>Miejsce zamieszkania: Opole</p>
                           <p className='dataOtherParagtapf'>Telefon: 781 404 746</p>
                           <p className='dataOtherParagtapf'>Email: charkiewicz.pawe4@gmail.com</p>
                       </div>

                       <div className='personalData_container'>
                            <p className='datacv'>Języki</p>
                            <p className='dataOtherParagtapf'>Język angielski: B1/B2</p>
                       </div>

                       <div className='personalData_container'>
                            <p className='datacv'>Uprawienia</p>
                            <p className='dataOtherParagtapf'>Prawo jazdy kat.B</p>
                            <p className='dataOtherParagtapf'>Operator wózków jezdniowych</p>
                       </div>
                    </div>

                    <div className='cvrightSide'>
                         <div className='cvschool_container'>
                             <p className='schoolfirstParagraph'>Wykształcenie</p>
                             <div className='singleCvSchool_contianer'>
                                <p className='dateSchool'>16.06.2022</p>
                                <p className='cvNameSchool'>Stuida podyplomowe WSB Wrocław</p>
                                <p className='cvTitleSchool'>Programista Front-End z Angular</p>
                             </div>

                             <div className='singleCvSchool_contianer'>
                                <p className='dateSchool'>11.02.2015</p>
                                <p className='cvNameSchool'>Stuida inzynierskie - Politechnika Opolska</p>
                                <p className='cvTitleSchool'>Kierunek: Logistyka</p>
                             </div>
                         </div>

                         <div className='cvschool_container'>
                             <p className='schoolfirstParagraph'>Technologie</p>
                             <div className='technologies_container'>
                                <div className='techologies_leftSide'>
                                    <li>HTML</li>
                                    <li>CSS i SCSS</li>
                                    <li>JavaScirpt</li>
                                    <li>TypeScript</li>
                                    <li>React.js</li>
                                    <li>React Native</li>
                                </div>
                                <div className='techologies_rightSide'>
                                    <li>MongoDB</li>
                                    <li>jQuerry</li>
                                    <li>Node.js</li>
                                    <li>Express</li>
                                    <li>Scrum</li>
                                    <li>Jira</li>
                                </div>
                             </div>
                         </div>

                         <div className='cvschool_container'>
                             <p className='schoolfirstParagraph'>Doświadczenie IT</p>
                             <div className='singleCvSchool_contianer'>
                                <p className='dateSchool'>2023</p>
                                <p className='cvNameSchool'>Opolskie Centrum Leczenie i Rehabilitacji Dzikich Zwierząt "Avi</p>
                                <p className='cvTitleSchool'>Budowa pod patronatem fundacji aplikacji mobilnej dotyczącej pierwszej pomocy dzikim zwierzętom: w trakcie</p>
                             </div>

                             <div className='singleCvSchool_contianer'>
                                <p className='dateSchool'>2023</p>
                                <p className='cvNameSchool'>Radca Prawny Alicja Kaszta</p>
                                <p className='cvTitleSchool'>Aplikajca do raportowania i wyznaczania zadań</p>
                             </div>

                             <div className='singleCvSchool_contianer'>
                                <p className='dateSchool'>2023</p>
                                <p className='cvNameSchool'>Exago</p>
                                <p className='cvTitleSchool'>Strona internetowa z blogiem. strona posiada wbudowany moduł umozliwiający właścicielowi łatwą i szybką zmianę tekstów, zdjęć oraz animacji. Strona powstała, obecnie w trakcie uzgdniania estetyki :http://hrr4.usermd.net/</p>
                             </div>
                         </div>

                         <div className='cvschool_container'>
                             <p className='schoolfirstParagraph'>Doświadczenie</p>
                             <div className='singleCvSchool_contianer'>
                                <p className='dateSchool'>01.06.2017-obecnie</p>
                                <p className='cvNameSchool'>Specjalista ds. realizacji zleceń ORLEN Centrum Serwisowe Sp. z o.o.</p>
                                <p className='cvTitleSchool'>Przyjmowanie zgłoszeń awarii stacji benzynowych, zdalna naprawa, kontakt z serwisem informatycznym odpowiedzialnym za wdrożenie nowego oprogramowania oraz procesów na stacjach,</p>
                             </div>

                             <div className='singleCvSchool_contianer'>
                                <p className='dateSchool'>27.06.2016-01.06.2017</p>
                                <p className='cvNameSchool'>Referent ds. realizacji zleceń ORLEN Centrum Serwisowe Sp. z o.o.</p>
                             </div>

                             <div className='singleCvSchool_contianer'>
                                <p className='dateSchool'>15.07.2015-17.06.2016</p>
                                <p className='cvNameSchool'>Referent ds. Administracji Tba Express Sp. z o.o.</p>
                                <p className='cvTitleSchool'>Obsługa biura placówki, monitorowanie jakości doręczeń przesyłek kurierskich, dbałość o terminowe rozliczenie pobrań, pomoc w koordynacji pracy kurierów, wyjaśnianie statusów przesyłek niedoręczonych</p>
                             </div>

                             <div className='singleCvSchool_contianer'>
                                <p className='dateSchool'>18.08.2017-14.09.2015</p>
                                <p className='cvNameSchool'>Magazynier Siódemka S.A</p>
                             </div>
                         </div>


                    </div>
                </div>


            </div>

             <button className='greenBtn btnDowlandCV' onClick={(e)=>download(e)}>Pobierz CV</button> 
           </div> 
    </div>
  

  )
}

export default CV