import {Canvas, useFrame} from '@react-three/fiber'
import {OrbitControls, Center, useGLTF, Environment, AccumulativeShadows, RandomizedLight} from '@react-three/drei'
import React, { useEffect, useRef, useState } from "react";
import { HemisphereLight } from 'three';

export const Meteorite=({ Numberfov, NumberTop, Speed, Metheoridrotation})=>{


     
     const [speedLeft, setSpeedLeft]=useState(0)

     const [speedTop, setSpeedTop]=useState(NumberTop)

     useEffect(()=>{
       const interval=setInterval(()=>{
          if(speedLeft<100){setSpeedLeft(speedLeft+Speed)}
          if(speedLeft>=100){setSpeedLeft(-10)}

          if(speedTop>-40){setSpeedTop(speedTop-Speed)}
          if(speedTop<=-40){setSpeedTop(NumberTop)}
       },8)
       return()=>clearInterval(interval)
     })

     useEffect(()=>{
      setSpeedTop(Math.random()*100+40)
     },[])

  return(
      <div className='singleMeteorite'
        style={{
          marginLeft:`${speedLeft}vw`,
          marginTop:`${speedTop}vh`
        }}
      >
        <Canvas
        camera={{position:[-1,-80,0.5], fov:Numberfov}}
        eventSource={document.getElementById('root')}
        eventPrefix='client'
        style={{
            // marginTop:`${randomMarginTop}vh`,
            width:'20vw',
            height:'40vh'
        }}
        >
           <pointLight position={[10, 10, 10]} />
           <pointLight position={[-10, -10, -10]} />
          <Center>
            <SingleMeteorite Metheoridrotation={Metheoridrotation}/>
          </Center>
          <spotLight position={[10, 15, 10]} angle={20} />
        </Canvas>
      </div>
  )
}


export function SingleMeteorite({props,Metheoridrotation}) {
    const group=useRef()

  
      const rotationY=(Math.random()*9,5)/1000
      const rotationX=(Math.random()*9,5)/1000
   
    useFrame((state, delta) => (
        group.current.rotation.y -= Metheoridrotation
        ))
    useFrame((state, delta) => (
        group.current.rotation.x -= Metheoridrotation
        ))
    const { nodes, materials } = useGLTF("/Meteoryt.glb");
    return (
      <group ref={group} {...props} dispose={null}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Sphere.geometry}
          material={materials["Meteoryt.002"]}
          scale={[1.628, 0.691, 1.024]}
        />
      </group>
    );
  }