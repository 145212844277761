import React from 'react'
import './FirstPicture.css'
import FirstDisplay from './FirstDisplay'


function FirstPicture() {
  return (
    <div>
        <FirstDisplay/>
    </div>
  )
}

export default FirstPicture