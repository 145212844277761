import axios from 'axios'

export const SendFirstRegister=async(register)=>{
    try {
        const response=await axios.post('api/charkiewicz_code/firstRegister/first_register',register)
        return response.data
    } catch (error) {
        throw(error)
    }
}

export const GetFirstRegister=async(register)=>{
    try{
        const response=await axios.get('api/charkiewicz_code/firstRegister/get_first_register')
        return response.data
    }catch(error){
        throw error
    }
}